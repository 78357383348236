.login{
  background: white !important;
}

.login__topwrapper{
  margin: 0;
}

.login__tablewrapper{
  display: table;
  width: 100%;
  height: 100vh;
}

.login__colpadding{
  padding: 0px;
}

.login__tablecell{
  display: table-cell;
  vertical-align: middle;
}

.login__left-title{
  color: white !important;
  font-weight: 600;
  letter-spacing: 0.72px;
  font-size: 24px;
  margin-top: -20px;
}

.login__leftmainwrapper{
  background: #2a2a2a;
  text-align: center;
}

.login__rightcontainer{
  width: 50%;
  margin: auto;
}

.login__rightsubcontainer{
  padding: 40px;
  box-shadow: 0 0 36px #ccc;
}

.login__verticalgap{
  margin-bottom: 20px;
}

.login__logo{
  width: 30%;
}

.login__leftheader{
  color: #2b2b2b !important;
  font-weight: 600;
}

.login__label{
  color: #2b2b2b;
  letter-spacing: 0.5px;
}