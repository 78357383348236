.smalldiv__samerow{
  border: 1px solid #dee2e6;
  text-align: left;
  padding: 8px;

  p {
    margin-top: 0px;
  }
}

.smalldiv__cardsContainer{
  margin-bottom: 15px;
  gap: 10px;
  margin-left: 0px;
}

.smalldiv__flexselfcenter{
  align-items: center;
}

.smalldiv__table-btn {
  background: transparent;
  border: none;
  line-height: 14px;
  @include directify($directions) {
    #{directed('padding-left')}: 20px;
    #{directed('padding-right')}: 0px;
  }
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  color: $color-additional;
  cursor: pointer;
  transition: all 0.3s;

  svg {
    height: 16px;
    width: 16px;
    top: -2px;
    @include directify($directions) {
      #{directed('left')}: 0px;
    }
    fill: $color-additional;
    transition: all 0.3s;
  }

  &:hover {
    color: $color-red-hover;

    svg {
      fill: $color-red-hover;
    }
  }
}

.smalldiv__statushighlight{
  padding: 2px 10px 2px 10px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}

.smalldiv__bluestatus{
  background: rgba(0, 119, 255, 0.2);
  color: rgb(0, 119, 255);
}

.smalldiv__greenstatus{
  background: rgb(29, 175, 103, 0.2);
  color: rgb(29, 175, 103);
}

.smalldiv__bluishgreenstatus{
  color: rgb(3, 165, 165);
  background: rgb(3, 165, 165, 0.2);
}

.smalldiv__orangestatus{
  background: rgb(255, 185, 90, 0.2);
  color: rgb(255, 185, 90);
}

.smalldiv__listheader{
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4{
    font-weight: 600;
  }
}
.cancel-button {
  width: 99px;
  height: 19px;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
}


.view-material-summary {
  width: 174px;
  height: 19px;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
