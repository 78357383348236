p, h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  font-weight: 400;
  margin-bottom: 0;

  @include themify($themes) {
    color: themed('colorText');
  }
}

.fontsz-3 {
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
}
.fontsz-2 { 
  font-family: Gilroy;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}
.font-bld{ font-weight: bold}
p {
  margin: 10px 0 0 0;

  &:first-child {
    margin: 0;
  }
}

.font-hv-success{
  color: #03a5a5;
  fill: #03a5a5;
&:hover{ color: white; fill: white}
}


h1 {
  font-size: 36px;
  line-height: 48px;

  &.subhead {
    font-size: 30px;
    color: $color-additional;
    line-height: 36px;
  }
}

h2 {
  font-size: 30px;
  line-height: 36px;

  &.subhead {
    font-size: 24px;
    color: $color-additional;
    line-height: 32px;
  }
}

h3 {
  font-size: 24px;
  line-height: 32px;

  &.subhead {
    font-size: 18px;
    color: $color-additional;
    line-height: 24px;
  }
}

h4 {
  font-size: 18px;
  line-height: 24px;

  &.subhead {
    font-size: 12px;
    color: $color-additional;
    line-height: 16px;
  }
}

h5 {
  font-size: 14px;
  line-height: 18px;

  &.subhead {
    font-size: 10px;
    color: $color-additional;
    line-height: 12px;
  }
}

h6 {
  font-size: 12px;
  line-height: 16px;

  &.subhead {
    font-size: 8px;
    color: $color-additional;
    line-height: 10px;
  }
}

.bold-text {
  font-weight: 700;
}

.typography--inline {
  display: flex;
  flex-wrap: wrap;

  * {
    margin-top: auto;
    margin-right: 15px;
  }
}

.typography-card {

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 10px;
  }
}

blockquote {
  font-size: 13px;
  font-style: italic;
  border-left: 3px solid $color-accent;
  margin-bottom: 0;
  padding-left: 10px;
}

.highlight {
  background-color: $color-accent;
  color: #ffffff;
}

.red-text {
  color: #ad4444;
}

.page-title {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 20px;
  color: #03a5a5 !important ;
  text-align: left;
  &:last-child {
    margin-bottom: 30px;
  }

  &.page-title--not-last {
    margin-top: 20px;
  }
}

.page-subhead {
  font-weight: 600;
  color: #242424 !important;
  margin-bottom: 20px;
  &.subhead {
    font-size: 14px;
    opacity: 0.7;
  }
}

::selection {
  color: #ffffff;
  background: $color-accent;
}

