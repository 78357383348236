.card {
  width: 100%;
  padding-bottom: 30px;
  height: 100%;
  border: none;
  background-color: transparent;

  &.card--not-full-height {
    height: auto;
  }
}

.card-body {
  padding: 30px;
  height: 100%;
  border-radius: 5px;

  @include themify($themes) {
    background-color: themed('colorBackground');
  }
}

.Rectangle-478 {
  width: 190px;
  height: 190px;
  margin: 33px 0px 0px 10px;
  //padding: 69.3px 81px 83px;
  object-fit: contain;
  border-radius: 5px;
  border: dashed 1px #03a5a5;
}

.card__title {
  margin-bottom: 30px;
  text-transform: uppercase;
  position: relative;

  &:not(:first-child) {
    margin-top: 40px;
  }

  .subhead {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    margin-top: 3px;
  }

  * {
    margin-bottom: 0;
  }

  h5 {
    font-size: 13px;
  }
}

.squared-corner-theme {

  .card-body {
    border-radius: 0;
  }
}

.blocks-with-shadow-theme {

  .card-body {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  }

}

.flex-container {
  display: flex;
  flex-wrap: nowrap;
}

.flex-container>div {
  text-align: center;
}
.my-modal .modal-content {
  border-radius: 0;
  border: none;
  box-shadow: none;
  position: relative;
  padding: 50px 40px 60px 40px;
  text-align: center;
  background-color: transparent;
}